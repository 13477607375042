import { Link } from 'gatsby';
import React from 'react';

import ContentSection from '../components/content-section';
import Layout from '../components/layout';
import MetaData from '../components/meta-data';

const HaftungsausschlussPage = (props) => {
  const title = 'Haftungsausschluss';

  return (
    <Layout path={props.location.pathname}>
      <MetaData
        title={title}
        description="Der Haftungsausschluss der Webseite von Coiffeur Cocoon."
      />

      <ContentSection>
        <h1>{title}</h1>

        <h2 className="mt-30">
          <small>1. Inhalt der Webseite</small>
        </h2>
        <p>
          Die Inhalte dieser Webseite wurden mit grösstmöglicher Sorgfalt
          erstellt. Dennoch übernehmen wir keine Gewähr für die Richtigkeit,
          Vollständigkeit oder Aktualität der bereitgestellten Informationen.
          Alle Angaben dienen ausschliesslich zu Informationszwecken und sind
          unverbindlich. Eine Haftung für Schäden materieller oder ideeller Art
          wird ausgeschlossen, soweit diese nicht auf grob fahrlässiges oder
          vorsätzliches Verhalten unsererseits zurückzuführen sind oder eine
          zwingende gesetzliche Haftung besteht.
        </p>

        <h2 className="mt-30">
          <small>2. Verweise und Links</small>
        </h2>
        <p>
          Unsere Webseite enthält Links zu externen Webseiten Dritter. Zum
          Zeitpunkt der Verlinkung waren keine rechtswidrigen Inhalte erkennbar.
          Eine kontinuierliche inhaltliche Kontrolle der verlinkten Seiten ist
          jedoch nicht möglich. Falls uns Rechtsverstösse bekannt werden,
          entfernen wir die betroffenen Links umgehend. Für die Inhalte der
          verlinkten Seiten ist ausschliesslich der jeweilige Anbieter oder
          Betreiber verantwortlich.
        </p>

        <h2 className="mt-30">
          <small>3. Urheber- und Kennzeichenrecht</small>
        </h2>
        <p>
          Die Inhalte und Fotos auf dieser Webseite sind urheberrechtlich
          geschützt. Jede Verwendung, Vervielfältigung oder Verbreitung ohne
          vorherige schriftliche Genehmigung ist nicht gestattet. Alle auf
          dieser Webseite genannten Marken und Logos sind Eigentum der
          jeweiligen Rechteinhaber. Die blosse Nennung impliziert keine
          Verbindung oder Billigung durch den Markeninhaber.
        </p>

        <h2 className="mt-30">
          <small>4. Datenschutz</small>
        </h2>
        <p>
          Informationen zur Verarbeitung personenbezogener Daten und zur
          Verwendung von Cookies finden Sie in unserer
          <Link to="/datenschutz/">Datenschutzerklärung</Link>.
        </p>

        <h2 className="mt-30">
          <small>5. Dienstleistungen & Terminbuchungen</small>
        </h2>
        <p>
          Die auf dieser Webseite dargestellten Dienstleistungen und Preise
          dienen zur Orientierung und sind unverbindlich. Änderungen sind
          jederzeit möglich. Bitte beachten Sie, dass vereinbarte Termine
          verbindlich sind. Falls ein Termin nicht wahrgenommen werden kann,
          bitten wir um eine rechtzeitige Absage. Kurzfristige Stornierungen
          können kostenpflichtig sein.
        </p>

        <h2 className="mt-30">
          <small>6. Änderungen des Haftungsausschlusses</small>
        </h2>
        <p>
          Wir behalten uns vor, diesen Haftungsausschluss jederzeit anzupassen
          oder zu aktualisieren.
        </p>
      </ContentSection>
    </Layout>
  );
};

export default HaftungsausschlussPage;
